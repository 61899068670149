module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.0_gatsby@5.9.0_graphql@16.8.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/fav/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d2f6c1dc917e25bade5de106068619bf"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.13.1_gatsby@5.9.0_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-KZWDJDV3N2"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.13.0_gatsby@5.9.0_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/search/"]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.13.0_gatsby-plugin-sharp@5.13.0_gatsby@5.9.0/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.9.0_babel-eslint@10.1.0_eslint-import-resolver-typescript@3.5.5_react-dom@18.2.0_react@18.2.0_typescript@5.0.4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
